import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@redux-requests/react';
import { Link, useLocation } from 'react-router-dom';
import { getTests } from '../store/requests';
import Test from '../components/Test';
import Faculty from '../components/Faculty';

const faculty = [
  {
    name: 'Ike K. Ahmed',
    titles: 'MD - Chair',
    picture: 'ahmed',
  },
  {
    name: 'Delan Jinapriya',
    titles: 'BSc, MD',
    picture: 'jinapriya',
  },
  {
    name: 'Kaisra Esmail',
    titles: 'MD',
    picture: 'esmail',
  },
  {
    name: 'Georges Durr',
    titles: 'MD, GAASS',
    picture: 'durr',
  },
  {
    name: 'Priya Gupta',
    titles: 'MD',
    picture: 'gupta',
  },
];

const survey = [
  {
    question:
      'Do you think the module met the stated program learning objectives?',
    type: 'choice' as const,
    choices: ['Yes', 'No'],
  },
  {
    question: 'Was the information in the online program presented clearly?',
    type: 'choice' as const,
    choices: ['Yes', 'No'],
  },
  {
    question:
      'Did the online program enhance your knowledge on the subject matter?',
    type: 'choice' as const,
    choices: ['Yes', 'No'],
  },
  {
    question: 'Did you find the online program was relevant to your practice?',
    type: 'choice' as const,
    choices: ['Yes', 'No'],
  },
  {
    question: 'Did the online program meet the following CanMEDs Roles?:',
    type: 'container' as const,
    subQuestions: [
      {
        question: 'Medical Expert',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
      {
        question: 'Communicator ',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
      {
        question: 'Collaborator ',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
      {
        question: 'Leader ',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
      {
        question: 'Health Advocate',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
      {
        question: 'Professional',
        type: 'choice' as const,
        choices: ['Yes', 'No'],
      },
    ],
  },
  {
    question: 'Did you perceive any bias in the webinar presentation?',
    choices: ['Yes', 'No'],
    type: 'choice' as const,
    subQuestions: [
      {
        question: 'If yes, please describe:',
        type: 'textarea' as const,
      },
    ],
  },
  {
    question:
      'What impact do you think learning gained from this activity will have on your practice?',
    type: 'textarea' as const,
  },
  {
    question: 'What topic areas would you like to see future modules focus on?',
    type: 'textarea' as const,
  },
];

const Cos2021Page: React.FC<{}> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTests());
  }, [dispatch]);
  const location = useLocation();

  const tests = useQuery({ type: getTests.type });
  if (tests.loading || !tests.data) {
    return <div>'Loading...'</div>;
  }

  return (
    <>
      <div className="row">
        <div className="col" style={{ textAlign: 'right', paddingBottom: 8 }}>
          <Link to="/">Back to homepage</Link>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <img
            src="/images/backtothefuture-header.jpg"
            alt=""
            style={{
              maxWidth: '100%',
              width: '100%',
            }}
          />
        </div>
      </div>
      <div className="row" style={{ marginTop: 30 }}>
        <div className="col">
          {location.pathname.match(/\/cos2021\/?$/) && (
            <>
              <h4>Learning Objectives</h4>
              <p>At the end of this module, participants will be able to:</p>
              <ul>
                <li>
                  Recognize the importance of setting IOP targets in patients
                  with glaucoma
                </li>
                <li>
                  Explain how to monitor progression through VF, OCT changes and
                  other tools
                </li>
                <li>
                  Identify adherence issues in patients and when to refer
                  patients for surgical interventions
                </li>
                <li>
                  Discuss the timing and order of surgical strategies, including
                  considerations for post-surgical medical management
                </li>
              </ul>
              <h4>Faculty</h4>
              <Faculty label="" members={faculty} />
            </>
          )}

          <Test
            questions={tests.data[0].questions}
            testNum={0}
            testId={tests.data[0].id}
            videoFilename="2021/Back to the Future_no-intro"
            surveyQuestions={survey}
          />

          <div className="statement">
            <p>
              This module was co-developed by the Canadian Ophthalmological
              Society and Allergan/AbbVie and was planned to achieve scientific
              integrity, objectivity and balance.
            </p>
            <p>
              This activity is an Accredited Self-Assessment Program (Section 3)
              as defined by the Maintenance of Certification Program of the
              Royal College of Physicians and Surgeons of Canada, and approved
              by the Canadian Ophthalmological Society. You may claim a maximum
              of 1.5 hours (credits are automatically calculated).
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cos2021Page;
