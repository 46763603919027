import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import FileSaver from 'file-saver';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import app from '../store/app';
import { createUser, getUser, submitAnswers } from '../store/requests';
import {
  getPretests,
  getTests,
  getUserId,
  getSessionId,
  getFirstName,
  getLastName,
  getEmail,
  getCompletedPretest,
  getCompletedTest,
  getCompletedSurvey,
} from '../store/selectors';
import { Question, SurveyQuestion } from '../types';
import QuestionComponent from './Question';
import Survey from './Survey';

interface RequestResult {
  status: number;
  data: any;
}

interface TestProps {
  questions: Question[];
  testNum: number;
  testId: number;
  videoFilename: string;
  surveyQuestions?: SurveyQuestion[];
}

const Test: React.FC<TestProps> = ({
  questions,
  testNum,
  testId,
  videoFilename,
  surveyQuestions,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [formFirstName, setFormFirstName] = useState('');
  const [formLastName, setFormLastName] = useState('');
  const [formEmail, setFormEmail] = useState('');

  const userId = useSelector(getUserId);
  const sessionId = useSelector(getSessionId);
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const email = useSelector(getEmail);
  const dispatch = useDispatch();
  const preTests = useSelector(getPretests);
  const tests = useSelector(getTests);
  const preTestsAnswers = preTests[testNum].answers;
  const testsAnswers = tests[testNum].answers;
  const hasCompletedPretest = useSelector(getCompletedPretest);
  const hasCompletedTest = useSelector(getCompletedTest);
  const hasCompletedSurvey = useSelector(getCompletedSurvey);

  const renderPretestQuestion = (question: Question) => {
    let givenAnswer = undefined;
    try {
      if (hasCompletedPretest) {
        givenAnswer = preTestsAnswers[question.id];
      }
    } catch (e) {}
    return (
      <QuestionComponent
        key={`question-${question.id}`}
        testId={testNum}
        question={question}
        isPretest={true}
        givenAnswer={givenAnswer}
      />
    );
  };
  const renderTestQuestion = (question: Question) => {
    let givenAnswer = undefined;
    try {
      if (hasCompletedTest) {
        givenAnswer = testsAnswers[question.id];
      }
    } catch (e) {}
    return (
      <QuestionComponent
        key={`question-${question.id}`}
        testId={testNum}
        question={question}
        isPretest={false}
        givenAnswer={givenAnswer}
      />
    );
  };
  const checkHasAnsweredAllQuestions = (isPretest: boolean) => {
    const questionCount = questions.length;
    const answers = isPretest ? preTestsAnswers : testsAnswers;
    let count = 0;
    for (const key in answers) {
      if (Object.prototype.hasOwnProperty.call(answers, key)) {
        const element = answers[key];
        if (element !== undefined && element !== null) {
          count++;
        }
      }
    }
    return count === questionCount;
  };
  const submitGivenAnswers = async (isPretest: boolean) => {
    const answers = isPretest ? preTestsAnswers : testsAnswers;
    const result = await dispatch(
      submitAnswers(testId, isPretest, sessionId, userId, answers),
    );
    console.log(result);
  };
  const downloadCertificate = async () => {
    try {
      const response = await axios({
        // url: `http://173.209.57.134:3001/api/users/certificate`,
        url: `/api/users/certificate`,
        method: 'POST',
        responseType: 'blob', // this is needed to trigger a direct download
        data: {
          index: testNum,
          name: `${firstName} ${lastName}`,
        },
      });
      FileSaver.saveAs(new Blob([response.data]), `Certificate.pdf`);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };
  switch (pathname) {
    case '/cos2021/step1':
    default:
      return (
        <div
          style={{
            display: 'flex',
            height: 200,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fafafa',
            padding: '8px 12px',
            marginBottom: 40,
          }}
        >
          <button
            type="button"
            onClick={() => {
              history.push('/cos2021/signin');
            }}
          >
            Begin
          </button>
        </div>
      );
    case '/cos2021/signin': // 2
      if (firstName && lastName && email) {
        // already logged in
        return <Redirect to="/cos2021/pretest" />;
      }
      return (
        <div className="form">
          <h4>Your information</h4>
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setFormFirstName(e.currentTarget.value);
              }}
              value={formFirstName}
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setFormLastName(e.currentTarget.value);
              }}
              value={formLastName}
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              onChange={(e) => {
                setFormEmail(e.currentTarget.value);
              }}
              value={formEmail}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={async () => {
              if (
                formFirstName !== '' &&
                formLastName !== '' &&
                formEmail !== ''
              ) {
                dispatch(app.actions.setFirstName(formFirstName));
                dispatch(app.actions.setLastName(formLastName));
                dispatch(app.actions.setEmail(formEmail));
                const existResult = ((await dispatch(
                  getUser(formEmail),
                )) as unknown) as RequestResult;
                if (existResult.status !== 200) {
                  const createResult = ((await dispatch(
                    createUser(formFirstName, formLastName, formEmail),
                  )) as unknown) as RequestResult;
                  if (createResult.status !== 201) {
                    window.alert('Error while creating user, please try again');
                    return;
                  }
                  dispatch(app.actions.setUserId(createResult.data.uuid));
                } else {
                  dispatch(app.actions.setUserId(existResult.data.uuid));
                }
                history.push('/cos2021/pretest');
              } else {
                window.alert('Please enter your name and email');
              }
            }}
          >
            Submit
          </button>
        </div>
      );
    case '/cos2021/pretest': // 3
      return (
        <div className="form">
          <h4>Pre-test</h4>
          {hasCompletedPretest && (
            <div className="alert alert-info">
              You already completed the pre-test. Please click the Next button
              below to go to the next step.
            </div>
          )}
          <div>{questions.map(renderPretestQuestion)}</div>
          <button
            type="button"
            onClick={async () => {
              if (hasCompletedPretest) {
                history.push('/cos2021/video');
              } else if (checkHasAnsweredAllQuestions(true)) {
                dispatch(app.actions.setCompletedPretest(true));
                await submitGivenAnswers(true);
                history.push('/cos2021/video');
              } else {
                window.alert('Please answer all questions');
              }
            }}
          >
            {hasCompletedPretest ? 'Next' : 'Submit'}
          </button>
        </div>
      );
    case '/cos2021/video': // 4
      return (
        <div className="form">
          <h4>Video</h4>
          <video
            src={`https://cos2020.s3.ca-central-1.amazonaws.com/${videoFilename}.mp4`}
            controls
            poster={`https://cos2020.s3.ca-central-1.amazonaws.com/${videoFilename}.jpg`}
          ></video>
          <button
            type="button"
            onClick={() => {
              history.push('/cos2021/posttest');
            }}
          >
            Next
          </button>
        </div>
      );
    case '/cos2021/posttest': // 5
      return (
        <div className="form">
          <h4>Post-test</h4>
          {hasCompletedTest && (
            <div className="alert alert-info">
              You already completed the post-test. Please click the Next button
              below to go to the next step.
            </div>
          )}
          <div>{questions.map(renderTestQuestion)}</div>
          <button
            type="button"
            onClick={async () => {
              if (hasCompletedTest) {
                history.push('/cos2021/nextsteps');
              } else if (checkHasAnsweredAllQuestions(false)) {
                dispatch(app.actions.setCompletedTest(true));
                await submitGivenAnswers(false);
                history.push('/cos2021/nextsteps');
              } else {
                window.alert('Please answer all questions');
              }
            }}
          >
            {hasCompletedTest ? 'Next' : 'Submit'}
          </button>
        </div>
      );
    case '/cos2021/nextsteps': // 6
      return (
        <div className="form">
          <h4>Next steps</h4>
          <p>
            Thank you for submitting your post-test. You can now view your
            summary of responses, complete the evaluation survey and download
            your certificate of completion.
          </p>
          <div className="row">
            <div className="col">
              <button
                type="button"
                onClick={() => {
                  history.push('/cos2021/summary');
                }}
              >
                View Summary
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                onClick={() => {
                  history.push('/cos2021/survey');
                }}
              >
                Evaluation Survey
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                onClick={() => {
                  downloadCertificate();
                }}
              >
                Download Certificate
              </button>
            </div>
          </div>
        </div>
      );
    case '/cos2021/summary': // 7
      return (
        <div className="form">
          <h4>Summary</h4>
          <div style={{ textAlign: 'right', marginBottom: 15 }}>
            <button
              type="button"
              onClick={() => {
                window.print();
              }}
              style={{
                marginRight: 15,
              }}
            >
              Print
            </button>
            <button
              type="button"
              onClick={() => {
                history.push('/cos2021/nextsteps');
              }}
            >
              Back
            </button>
          </div>
          {questions.map((question: Question) => {
            const { choices, correctAnswerId } = question;
            const givenPretestAnswerId = preTestsAnswers[question.id];
            const givenAnswerId = testsAnswers[question.id];
            const givenPretestAnswer = choices.find(
              (c) => c.id === givenPretestAnswerId,
            );
            const givenAnswer = choices.find((c) => c.id === givenAnswerId);
            const correctAnswer = choices.find((c) => c.id === correctAnswerId);

            return (
              <div className="summaryQuestion">
                <p>{question.text}</p>
                <p>
                  <span
                    style={{
                      fontStyle: 'italic',
                      color:
                        givenPretestAnswerId === correctAnswerId
                          ? 'gray'
                          : 'gray',
                    }}
                  >
                    Given answer (Pre-Test): {givenPretestAnswer?.text}
                  </span>
                  <br />
                  <span
                    style={{
                      color:
                        givenAnswerId === correctAnswerId ? 'green' : 'red',
                    }}
                  >
                    Given answer (Post-Test): {givenAnswer?.text}
                  </span>
                  <br />
                  Correct answer:{' '}
                  {correctAnswer?.alternateText
                    ? correctAnswer?.alternateText
                    : correctAnswer?.text}
                </p>
                <p
                  className="explanations"
                  dangerouslySetInnerHTML={{
                    __html: question.explanation,
                  }}
                />
                <p className="references">{question.references}</p>
              </div>
            );
          })}
          <div style={{ textAlign: 'right', marginTop: 15 }}>
            <button
              type="button"
              onClick={() => {
                window.print();
              }}
              style={{
                marginRight: 15,
              }}
            >
              Print
            </button>
            <button
              type="button"
              onClick={() => {
                history.push('/cos2021/nextsteps');
              }}
            >
              Back
            </button>
          </div>
        </div>
      );
    case '/cos2021/survey': // 8
      return (
        <div className="form">
          <h4>Evaluation Survey</h4>
          <div style={{ textAlign: 'right', marginBottom: 15 }}>
            <button
              type="button"
              onClick={() => {
                history.push('/cos2021/nextsteps');
              }}
            >
              Back
            </button>
          </div>
          <p>
            Thank you for completing the online accredited Self-Assessment
            Program. Your feedback allows us to measure the effectiveness of the
            initiative and plan future activities. Thank you for taking the time
            to complete the following short program evaluation.
          </p>
          <Survey
            questions={surveyQuestions!}
            testId={testId}
            testNum={testNum}
            returnCallback={() => {
              history.push('/cos2021/nextsteps');
            }}
            completed={hasCompletedSurvey}
          />
        </div>
      );
  }
};

export default Test;
