import { StoreState } from './types';

export const getPretests = (state: StoreState) => state.app.preTests;

export const getTests = (state: StoreState) => state.app.tests;

export const getSurveys = (state: StoreState) => state.app.surveys;

export const getUserId = (state: StoreState) => state.app.userId;

export const getFirstName = (state: StoreState) => state.app.firstName;

export const getLastName = (state: StoreState) => state.app.lastName;

export const getEmail = (state: StoreState) => state.app.email;

export const getSessionId = (state: StoreState) => state.app.sessionId;

export const getCompletedPretest = (state: StoreState) =>
  state.app.completedPretest;

export const getCompletedTest = (state: StoreState) => state.app.completedTest;

export const getCompletedSurvey = (state: StoreState) =>
  state.app.completedSurvey;
