import { createAction } from '@reduxjs/toolkit';

export const getTests = createAction('request/getTests', () => ({
  payload: {
    request: {
      url: '/tests',
    },
  },
}));

export const getUser = createAction('request/getUser', (email: string) => ({
  payload: {
    request: {
      url: `/users/${email}`,
    },
  },
}));

export const createUser = createAction(
  'request/createUser',
  (firstName: string, lastName: string, email: string) => ({
    payload: {
      request: {
        url: `/users`,
        method: 'POST',
        data: {
          firstName,
          lastName,
          email,
        },
      },
    },
  }),
);

export const submitAnswers = createAction(
  'request/submitAnswers',
  (
    testId: number,
    isPreTest: boolean,
    sessionId: string,
    userId: string,
    answers: Record<number, number>,
  ) => {
    const requests = [];
    for (const key in answers) {
      requests.push({
        url: `/tests/${testId}/questions/${key}/answers`,
        method: 'POST',
        data: {
          userId,
          choiceId: answers[key],
          sessionId,
          isPreTest,
        },
      });
    }
    return {
      payload: {
        request: requests,
      },
    };
  },
);

export const submitSurvey = createAction(
  'request/submitSurvey',
  (testId: number, sessionId: string, userId: string, values: any) => ({
    payload: {
      request: {
        url: `/tests/${testId}/surveys`,
        method: 'POST',
        data: {
          userId,
          sessionId,
          values,
        },
      },
    },
  }),
);
