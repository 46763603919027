import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { handleRequests } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appSlice from './store/app';

const axiosInstance = axios.create({
  // baseURL: `http://173.209.57.134:3001/api`,
  // baseURL: `http://cos2020symposia.com/api`,
  baseURL: `/api`,
  headers: {
    Accept: 'application/json',
  },
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, appSlice.reducer);

const { requestsReducer, requestsMiddleware } = handleRequests({
  driver: createDriver(axiosInstance),
});

const store = configureStore({
  reducer: combineReducers({
    app: persistedReducer,
    requests: requestsReducer,
  }),
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    ...requestsMiddleware,
  ],
});

export const persistor = persistStore(store);

export default store;
