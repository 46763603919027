import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import app from '../store/app';
import { Question as QuestionType } from '../types';

interface QuestionProps {
  question: QuestionType;
  testId: number;
  isPretest: boolean;
  givenAnswer?: number;
}

const Question: React.FC<QuestionProps> = ({
  question,
  testId,
  isPretest,
  givenAnswer,
}) => {
  const dispatch = useDispatch();
  const [value, setvalue] = useState<number | null>(null);
  return (
    <>
      <div style={{ marginTop: 20 }}>{question.text}</div>
      {question.choices.map((choice) => {
        let checked = false;
        if (typeof givenAnswer == 'number') {
          checked = givenAnswer === choice.id;
        } else {
          checked = value === choice.id;
        }
        return (
          <div key={choice.id}>
            <label>
              <input
                type="radio"
                name={`question${question.id}`}
                value={choice.id}
                checked={checked}
                disabled={typeof givenAnswer == 'number'}
                onChange={() => {
                  setvalue(choice.id);
                  dispatch(
                    app.actions[
                      isPretest ? 'setPretestAnswer' : 'setTestAnswer'
                    ]({
                      preTest: testId,
                      questionId: question.id,
                      choiceId: choice.id,
                    }),
                  );
                }}
              />
              &nbsp;{choice.text}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default Question;
