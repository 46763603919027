import React from 'react';
import { Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import IndexPage from './pages/IndexPage';
import Cos2021Page from './pages/COS2021Page';

function App() {
  return (
    <div className="app">
      <div className="container">
        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route path="/cos2021" component={Cos2021Page} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
