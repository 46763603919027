import React from 'react';
import { Link } from 'react-router-dom';

const IndexPage: React.FC<{}> = () => {
  return (
    <>
      <div className="row">
        <div className="col">
          <img
            src="/images/backtothefuture-header.jpg"
            alt=""
            style={{
              maxWidth: '100%',
              width: '100%',
            }}
          />
        </div>
      </div>
      <div className="row" style={{ marginTop: 30 }}>
        <div className="col">
          <h2>Section 3 accredited, on-demand learning</h2>
          <p>
            The Canadian Ophthalmological Society is pleased to provide
            ophthalmologists with this 90-minute accredited on-demand learning
            activity featuring content from a co-developed symposia that was
            presented during the 2021 COS Annual Meeting.
          </p>
          <p>
            To meet the criteria for accreditation, you will be asked to
            complete a pre-test and a post-test. In addition, you will be asked
            to complete an evaluation survey following the activity.
            Certificates of Participation will be available for download. Under
            Section 3 of the Maintenance of Certification Program of the Royal
            College of Physicians and Surgeons of Canada, you may claim a
            maximum of 1.5 hours for the completion of the learning activity.
          </p>
          <p>
            Click on the symposia image below to complete the online module.
            Please set aside 90 minutes to complete the module as the module has
            been designed to be completed in one sitting.
          </p>
        </div>
      </div>
      <div className="row" style={{ margin: '30px 0px' }}>
        <div className="col">
          <Link
            to="/cos2021"
            style={{
              display: 'block',
              textAlign: 'center',
            }}
          >
            <img
              src="/images/backtothefuture-header.jpg"
              alt="Back to the future"
            />
          </Link>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
