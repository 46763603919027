import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import app from '../store/app';
import { getSurveys, getSessionId, getUserId } from '../store/selectors';
import { SurveyQuestion } from '../types';
import { submitSurvey } from '../store/requests';

interface SurveyProps {
  testId: number;
  testNum: number;
  questions: SurveyQuestion[];
  returnCallback: () => void;
  completed: boolean;
}

const Survey: React.FC<SurveyProps> = ({
  questions,
  testId,
  testNum,
  returnCallback,
  completed,
}) => {
  const dispatch = useDispatch();
  const survey = useSelector(getSurveys)[testNum];
  const sessionId = useSelector(getSessionId);
  const userId = useSelector(getUserId);

  const validate = (questions: SurveyQuestion[], path = ''): boolean => {
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      if (
        (question.type === 'choice' || question.type === 'multiple') &&
        !survey.answers[`${path}${i}`]
      ) {
        return false;
      }

      if (question.subQuestions) {
        const subResult = validate(question.subQuestions, `${path}${i}/`);
        if (!subResult) {
          return false;
        }
      }
    }
    return true;
  };

  const getValues = (questions: SurveyQuestion[], path = ''): any => {
    const result = [];
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      const res = {
        question: question.question,
        answer:
          question.type !== 'container' ? survey.answers[`${path}${i}`] : null,
        subQuestions: question.subQuestions
          ? getValues(question.subQuestions, `${path}${i}/`)
          : null,
      };
      result.push(res);
    }
    return result;
  };

  const renderChoiceQuestion = (
    question: SurveyQuestion,
    questionIndex: number,
    path: string,
  ) => {
    const computedPath = `${path}${questionIndex}`;
    return (
      <div className="surveyQuestion" key={computedPath}>
        <div style={{ marginTop: 20 }}>{question.question}</div>
        {question.choices?.map((choice, index) => {
          return (
            <div key={index}>
              <label>
                <input
                  type="radio"
                  name={`survey_question_${computedPath}`}
                  value={choice}
                  checked={survey.answers[computedPath] === choice}
                  disabled={completed}
                  onChange={() => {
                    dispatch(
                      app.actions.setSurveyAnswer({
                        testIndex: testNum,
                        answer: choice,
                        questionId: computedPath,
                      }),
                    );
                  }}
                />
                &nbsp;{choice}
              </label>
            </div>
          );
        })}
        {question.subQuestions &&
          renderSubQuestions(question, `${path}${questionIndex}/`)}
      </div>
    );
  };

  const renderMultipleQuestion = (
    question: SurveyQuestion,
    questionIndex: number,
    path: string,
  ) => {
    const computedPath = `${path}${questionIndex}`;
    return (
      <div className="surveyQuestion" key={computedPath}>
        <div style={{ marginTop: 20 }}>{question.question}</div>
        {question.choices?.map((choice, index) => {
          return (
            <div key={index}>
              <label>
                <input
                  type="checkbox"
                  name={`survey_question_${questionIndex}`}
                  value={choice}
                  checked={
                    survey.answers[computedPath]?.includes(choice) || false
                  }
                  disabled={completed}
                  onChange={(e) => {
                    const add = e.currentTarget.checked;
                    const values = (survey.answers[
                      computedPath
                    ] as unknown) as string[];
                    let newValues = values ? [...values] : [];
                    if (add) {
                      newValues.push(choice);
                    } else {
                      const findIndex = newValues.indexOf(choice);
                      newValues = newValues
                        .slice(0, findIndex)
                        .concat(
                          newValues.slice(findIndex + 1, newValues.length),
                        );
                    }
                    dispatch(
                      app.actions.setSurveyAnswer({
                        testIndex: testNum,
                        answer: (newValues as unknown) as string,
                        questionId: computedPath,
                      }),
                    );
                  }}
                />
                &nbsp;{choice}
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTextQuestion = (
    question: SurveyQuestion,
    questionIndex: number,
    path: string,
  ) => {
    return (
      <div className="surveyQuestion" key={questionIndex}>
        <div style={{ marginTop: 20 }}>{question.question}</div>
        <textarea
          style={{ width: '100%', height: 100 }}
          value={survey.answers[`${path}${questionIndex}`]}
          disabled={completed}
          onChange={(e) => {
            dispatch(
              app.actions.setSurveyAnswer({
                testIndex: testNum,
                answer: e.currentTarget.value,
                questionId: `${path}${questionIndex}`,
              }),
            );
          }}
        />
      </div>
    );
  };

  const renderSubQuestions = (question: SurveyQuestion, path = '') => {
    return (
      <div style={{ marginLeft: 25, marginTop: -10 }}>
        {/* {question.subQuestions?.map(renderQuestion, path)} */}
        {renderQuestions(question.subQuestions!, path)}
      </div>
    );
  };

  const renderQuestion = (
    question: SurveyQuestion,
    index: number,
    path: string,
  ): JSX.Element | JSX.Element[] | undefined => {
    switch (question.type) {
      case 'choice':
        return renderChoiceQuestion(question, index, path);
      case 'multiple':
        return renderMultipleQuestion(question, index, path);
      case 'textarea':
        return renderTextQuestion(question, index, path);
      case 'container':
        return (
          <div className="surveyQuestion" key={path}>
            <div style={{ marginTop: 20 }}>{question.question}</div>
            {renderSubQuestions(question, `${path}${index}/`)}
          </div>
        );
    }
  };

  const renderQuestions = (questions: SurveyQuestion[], path = '') => {
    const arr = [];
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      arr.push(renderQuestion(question, i, `${path}`));
    }
    return arr;
  };

  return (
    <div className="survey">
      {completed && (
        <div className="alert alert-info">
          You already submitted your evaluation. Please click the Back button
          below to return to the Next Steps page.
        </div>
      )}
      {renderQuestions(questions)}
      <div>
        <button
          type="button"
          onClick={async () => {
            if (validate(questions)) {
              const values = getValues(questions);
              if (!values || !values.length) {
                window.alert('Please answer all questions');
                return;
              }
              if (!completed) {
                const result = await dispatch(
                  submitSurvey(testId, sessionId, userId, values),
                );
                // console.log(result);
                dispatch(app.actions.setCompletedSurvey(true));
              }
              await returnCallback();
            } else {
              window.alert('Please answer all questions');
            }
          }}
        >
          {completed ? 'Back' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default Survey;
